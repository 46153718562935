import { mapGetters } from "vuex";
import { scrollPropertyIntoView } from "@/modules/property/helpers/";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  name: "layout-controller",
  inject: ["EventBus"],
  mounted() {
    document.body.classList.remove("layout-base");
    this.addBodyClass("layout-page-with-map");
  },
  computed: {
    ...mapGetters({
      resultType: "property/resultType",
      showMobileMap: "mobile/showMobileMap",
      propertyView: "mobile/propertyView",
      selectedPropertyId: "household/selectedPropertyId",
    }),
  },
  watch: {
    resultType: function (val) {
      document.body.classList.remove([
        "with-results",
        "no-accessible-results",
        "no-results",
      ]);

      this.addBodyClass(val);
    },
    propertyView: [
      "updateBodyClass",
      "scrollPropertyIntoView",
      "toggleBodyScroll",
    ],
    selectedPropertyId: function (id) {
      const klass = "property-selected";
      if (id) {
        this.addBodyClass(klass);
      } else {
        document.body.classList.remove(klass);
      }
    },
  },
  methods: {
    addBodyClass(klass) {
      document.body.classList.add(klass);
    },
    updateBodyClass(viewName) {
      document.body.classList.remove(
        "property-view-list",
        "property-view-map-collapsed",
        "property-view-map-expanded",
      );
      const klass = "property-view-" + viewName;
      this.addBodyClass(klass);
    },
    scrollPropertyIntoView(viewName) {
      if (viewName === "list") {
        window.setTimeout(() => {
          scrollPropertyIntoView(this.selectedPropertyId);
        }, 200);
      }
    },
    toggleBodyScroll(viewName) {
      if (viewName === "map-expanded") {
        window.setTimeout(() => {
          const $map = document.getElementById("app-map");
          disableBodyScroll($map);
        }, 200);
      } else {
        clearAllBodyScrollLocks();
      }
    },
  },
};
